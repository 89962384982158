import { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { publicRoutes } from './routes';
import { DefaultLayout } from './components/Layout';
import { useTranslation } from 'react-i18next';

function App() {
    const { t } = useTranslation();

    return (
        <Router>
            <div className="App">
                <Routes>
                    {publicRoutes.map((route, index) => {
                        let Layout = DefaultLayout;
                        if (route.layout) {
                            Layout = route.layout;
                        } else if (route.layout === null) {
                            Layout = Fragment;
                        }
                        const Page = route.component;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page link={route.path} title={t(`content.routeTitle.${route.title}`)} />
                                    </Layout>
                                }
                                exact
                            />
                        );
                    })}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
