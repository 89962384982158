import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const DynamicBarChart = () => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            // Clear the previous chart
            if (chartData) {
                chartData.destroy();
            }

            // Generate new chart data
            const newChartData = {
                labels: Array.from({ length: 16 }, (_, i) => ''),
                datasets: [
                    {
                        backgroundColor: generateTransparentColors(),
                        borderColor: generateBorderColors(), // Border colors for the bars
                        borderWidth: 1, // Border width
                        data: generateRandomData(),
                    },
                ],
            };

            // Create and set the new chart
            const ctx = document.getElementById('chart-canvas').getContext('2d');
            const newChart = new Chart(ctx, {
                type: 'bar',
                data: newChartData,
                options: {
                    scales: {
                        x: {
                            display: false, // Hide the x-axis
                        },
                        y: {
                            display: false, // Hide the y-axis
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // Hide the legend
                        },
                    },
                },
            });

            setChartData(newChart);
        }, 1000);

        // Clear the interval when the component is unmounted
        return () => {
            if (chartData) {
                chartData.destroy();
            }
            clearInterval(interval);
        };
    }, [chartData]);

    // Function to generate a gradient of alternating transparent colors
    const generateTransparentColors = () => {
        const transparentColors = [];
        let isRed = false;

        for (let i = 0; i < 16; i++) {
            const color = isRed ? 'rgba(215, 85, 126, 0.5)' : 'rgba(97, 174, 249, 0.5)';
            transparentColors.push(color);
            isRed = !isRed;
        }
        return transparentColors;
    };

    // Function to generate an array of random data points between 20 and 100
    const generateRandomData = () => {
        const data = [];
        for (let i = 0; i < 16; i++) {
            data.push(Math.floor(Math.random() * 81) + 20); // Random number between 20 and 100
        }
        return data;
    };

    // Function to generate an array of border colors for the bars
    const generateBorderColors = () => {
        const borderColors = [];
        let isRed = false;

        for (let i = 0; i < 16; i++) {
            const color = isRed ? 'rgba(215, 85, 126, 1)' : 'rgba(97, 174, 249, 1)'; // Brighter color for border
            borderColors.push(color);
            isRed = !isRed;
        }
        return borderColors;
    };

    return (
        <div className="mx-4">
            <canvas id="chart-canvas" />
        </div>
    );
};

export default DynamicBarChart;
